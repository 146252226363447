import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { CommonIntervals, constants } from "~/common/constants";
import { useAnalytics } from "~/components/Analytics";
import {
  useFirstLoginModal,
  useProcessLogin,
} from "~/components/PersistentState";
import { processLogin } from "~/lib/client/endilApiCalls";

// Note: pathnames are the name of the path matching in our pages directory
// "/verticals/[verticalsParam]" is an example of pathname matching verticals page
const excludedPathnames = new Set([
  "/403",
  "/404",
  "/500",
  "/complete-logout",
  "/login",
  "/logout",
]);

export const ProcessLogin: React.FC<{}> = () => {
  const [recheck, setRecheck] = useState(true);
  const { pathname } = useRouter();
  const { data: sessionData, status, update } = useSession();
  const { analyticsUserIdentified, analyticsEnhancedTrack } = useAnalytics();
  const { setShowFirstLoginModal } = useFirstLoginModal();
  const {
    processLoginStart,
    setProcessLoginStart,
    processLoginSuccessID,
    setProcessLoginSuccessID,
  } = useProcessLogin();

  const pathnameAllowed = !excludedPathnames.has(pathname);

  const isComplete = processLoginSuccessID === sessionData?.mcAccountId;

  useEffect(() => {
    // Timestamp prevents multiple windows from running the same logic concurrently
    // while still allowing retry after ten seconds in the event of a failure or
    // a Piano window reload (hopefully this isn't a thing in the future!)
    const attemptAllowed =
      processLoginStart === null ||
      Date.now() - processLoginStart > CommonIntervals.TEN_SECONDS_IN_MS;

    // Recheck causes this component to reevaluate attemptAllowed until it succeeds
    if (!isComplete && recheck) {
      setRecheck(false);
      setTimeout(() => {
        setRecheck(true);
      }, CommonIntervals.ONE_SECOND_IN_MS);
    }

    const processLoginAsyncHandler = async () => {
      // First login logic
      if (
        !isComplete &&
        status === "authenticated" &&
        analyticsUserIdentified &&
        pathnameAllowed &&
        attemptAllowed
      ) {
        setProcessLoginStart(Date.now());

        const idpName = window.localStorage.getItem(
          constants.REGISTRATION_IDP_NAME_STORAGE_KEY
        );

        const {
          data: { isNewToPro, success },
        } = await processLogin({ idpName });

        await update();

        if (success) {
          setProcessLoginSuccessID(sessionData?.mcAccountId);
        } else {
          // Should consider adding a maximum retry count in the future
          return;
        }

        if (isNewToPro) {
          setShowFirstLoginModal(true);
          analyticsEnhancedTrack("User First Login", { idpName });
        }
      }
    };
    processLoginAsyncHandler();
  }, [
    analyticsEnhancedTrack,
    analyticsUserIdentified,
    isComplete,
    pathnameAllowed,
    processLoginStart,
    recheck,
    setRecheck,
    sessionData?.mcAccountId,
    setProcessLoginStart,
    setProcessLoginSuccessID,
    setShowFirstLoginModal,
    status,
  ]);

  return null;
};
