import { useSession } from "next-auth/react";
import { useContext, useEffect } from "react";
import { AnalyticsContext } from "~/components/Analytics/AnalyticsContext";
import { formatUserIdentityInformation } from "~/lib/shared/formatUserIdentity";
import { getAdditionalUserTraits } from "~/components/Analytics/getAdditionalUserTraits";
import {
  analyticsIdentify,
  analyticsReset,
  useAnalyticsUser,
} from "~/lib/client/analytics";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";

export const AnalyticsIdentifyUser = (): null => {
  const { analyticsUser } = useAnalyticsUser();
  const {
    analyticsUserIdentified,
    shallowMergeAnalyticsData,
    setAnalyticsUserId,
    setAnalyticsAnonymousId,
  } = useContext(AnalyticsContext);
  const { data: session, status: authStatus } = useSession();

  const userId = session?.mcAccountId;
  const authenticatedAnalyticsUserId = analyticsUser?.id();
  const anonymousAnalyticsUserId = analyticsUser?.anonymousId();

  useEffect(() => {
    // Identify the user with Segment Analytics
    if (authStatus === "authenticated" && !analyticsUserIdentified) {
      const identity = {
        ...formatUserIdentityInformation(session),
        ...getAdditionalUserTraits(),
        email: session.user.email,
      };
      analyticsIdentify(userId, identity);
      shallowMergeAnalyticsData({ analyticsUserIdentified: true, identity });
    }

    if (authStatus === "unauthenticated" && !analyticsUserIdentified) {
      const identity = {
        ...formatUserIdentityInformation(null),
        ...getAdditionalUserTraits(),
      };
      analyticsIdentify(identity, {
        integrations: {
          All: true,
          Iterable: false,
        },
      });
      shallowMergeAnalyticsData({ analyticsUserIdentified: true, identity });
    }

    /* Log the user out (clears Segment IDs in local storage) with Segment Analytics 
    once the user has been identified or if we have a stale Segment user ID in local
    storage. Retrieving the Segment user is async so we have to wait for 
    analyticsUser before we can rely on analyticsUserId */
    if (
      authStatus === "unauthenticated" &&
      analyticsUser &&
      authenticatedAnalyticsUserId
    ) {
      analyticsReset();
      shallowMergeAnalyticsData({
        analyticsUserIdentified: false,
        identity: {
          ...formatUserIdentityInformation(null),
          ...getAdditionalUserTraits(),
        },
      });
    }
  }, [
    analyticsUser,
    authenticatedAnalyticsUserId,
    authStatus,
    session,
    shallowMergeAnalyticsData,
    userId,
    analyticsUserIdentified,
  ]);

  // update the analyticsUserId on the context to the latest value available from segment
  useEffect(() => {
    setAnalyticsUserId(getValueOrDefault(authenticatedAnalyticsUserId, null));
    setAnalyticsAnonymousId(getValueOrDefault(anonymousAnalyticsUserId, null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedAnalyticsUserId, anonymousAnalyticsUserId, authStatus]);

  return null;
};
