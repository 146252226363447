import { Dialog } from "@headlessui/react";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { Modal } from "~/components/Modal";
import { useOnboardingModal } from "~/components/PersistentState";
import { useOnboardingUserInfo } from "~/components/UserOnboardingForm/useOnboardingUserInfo";
import { UserOnboardingForm } from "~/components/UserOnboardingForm/UserOnboardingForm";
import { upsertOnboardingUserInfo } from "~/lib/client/endilApiCalls";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import { Body, Card, Headline } from "~/ui-library";

export type UserOnboardingModalProps = {
  isOpen?: boolean;
};

export const UserOnboardingModal: React.FC<UserOnboardingModalProps> = ({
  isOpen,
}) => {
  const { setShowOnboardingModal, showOnboardingModal } = useOnboardingModal();
  const { data: session, status } = useSession();
  const userId = session?.mcAccountId;
  const { data } = useOnboardingUserInfo({ userId });

  const showModal = !!(
    status === "authenticated" &&
    showOnboardingModal &&
    getValueOrDefault(isOpen, true)
  );

  useEffect(() => {
    if (status === "authenticated" && userId && data) {
      // show the modal if we're missing one of the three values
      setShowOnboardingModal(
        !(data?.industry && data.salesforceOrganization && data.salesforceTitle)
      );
    }
  }, [data, setShowOnboardingModal, status, userId]);

  return (
    <Modal isOpen={showModal}>
      <Card className="w-full md:max-w-[550px] ">
        <div className="flex justify-center border-b-1 border-gray-300 px-m py-l">
          <Dialog.Title as="div" className="place-self-center text-gray-900">
            <Headline level={3} as="div">
              Complete Registration
            </Headline>
          </Dialog.Title>
        </div>
        <div className="p-m pb-l">
          <div className="mb-l">
            <Body bold className="inline" size="small">
              You&apos;re almost done!&nbsp;
            </Body>
            <Body className="inline text-gray-700" size="small">
              Doot diddly donger pepperoni. Vestibulum non faucibus orci. Sed
              iaculis vel quam ac vulputate. Proin fringilla porta venenatis.
              Phasellus nec nulla non nibh laoreet mollis.
            </Body>
          </div>
          <UserOnboardingForm
            email={session?.user.email}
            organization={data?.salesforceOrganization}
            title={data?.salesforceTitle}
            onSubmit={({ industry, organization, title }) => {
              upsertOnboardingUserInfo({
                organization,
                title,
                industry: industry.id,
              });
              setShowOnboardingModal(false);
            }}
          />
        </div>
      </Card>
    </Modal>
  );
};
